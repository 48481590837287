import React from 'react';
import useInputPostalCode
    from "v4/components/form/Field/components/InputPostalCode/hooks/useInputPostalCode";
import 'v4/components/form/Field/components/InputPostalCode/InputPostalCode.scss'

export default function InputPostalCode({fieldName, fieldValue, prefix, placeholder, error, attr, inputConstraints, postalCodeTarget, onChange}) {
    const {autocompleteCitiesList, handleSelect, handleChange } = useInputPostalCode(fieldName, fieldValue, postalCodeTarget, onChange);

    return (
        <div className="postal-code-field" data-is-required={!!inputConstraints.required}>
            <input className={`input-field${error ? ' has-error' : ''}`}
                   type="text"
                   data-name={fieldName}
                   id={prefix + fieldName}
                   value={fieldValue}
                   placeholder={placeholder}
                   data-is-required={!!inputConstraints?.required}
                   {...attr}
                   onChange={handleChange}/>

            {autocompleteCitiesList?.length > 0 && (
                <ul className="postal-code-field__menu">
                    {autocompleteCitiesList.map(({code, city}) => (
                        <li key={`${code} - ${city}`} data-code={code} data-city={city} onClick={handleSelect}>
                            {code} - {city}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}
