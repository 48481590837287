import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function InputRadio({onChange, fieldName, fieldValue, choices, htmlConstraints, prefix}) {
    const {t} = usePolTranslation();

    const handleInputChange = ({target: {value}}) => {
        return onChange(value);
    };

    return (
        <div className="input-field-radio-container" data-is-required={!!htmlConstraints?.required}>
            {
                choices.map(({label, value}, index) => {
                    const id = prefix + fieldName + index;

                    return (
                        <div key={label} className="input-field-radio">
                            <input type="radio"
                                   id={id}
                                   name={fieldName}
                                   data-name={fieldName}
                                   value={value}
                                   onChange={handleInputChange}
                                   checked={String(value) === String(fieldValue)}
                                   {...htmlConstraints}/>
                            <label htmlFor={id}>{t(label)}</label>
                        </div>
                    )
                })
            }
        </div>
    )
}
