import React from 'react';

export default function InputEmail({
                                       fieldName,
                                       fieldValue,
                                       prefix,
                                       placeholder,
                                       error,
                                       attr,
                                       inputConstraints,
                                       onChange
                                   }) {
    const handleChange = ({currentTarget: {value}}) => {
        onChange(value);
    }

    return (
        <input className={`input-field${error ? ' has-error' : ''}`}
               type="email"
               data-name={fieldName}
               id={prefix + fieldName}
               value={fieldValue}
               placeholder={placeholder}
               data-is-required={!!inputConstraints?.required}
               {...attr}
               onChange={handleChange}/>
    )
}
