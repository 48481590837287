import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function useProspectStats360TabProductViewActions(productsModules) {
    const {t} = usePolTranslation();

    const fields = [
        {
            name: 'sort',
            field: {
                type: 'select',
                choices: productsModules?.length > 0
                    ? productsModules[0].productConfiguration?.extraFields.flatMap(field => {
                        return [
                            {
                                value: field.fieldName,
                                label: `${field.fieldName} ${t('asc')}`,
                            },
                            {
                                value: `-${field.fieldName}`,
                                label: `${field.fieldName} ${t('desc')}`,
                            },
                        ]
                    })
                    : [],
            },
        },
        {
            name: 'search',
            field: {
                type: 'text',
                placeholder: t('search'),
            },
        }
    ];

    return {
        fields,
    }
}
