import React from 'react';

export default function InputRange({fieldName, fieldValue, attributes, htmlConstraints, onChange}) {
    const {min, step, max} = attributes;

    // get markers from step
    const markers = [];
    for (let i = min; i <= max; i += step) {
        markers.push(i);
    }

    const handleChange = ({currentTarget: {value}}) => {
        onChange(value);
    }

    return (
        <>
            <input type="range"
                   className="input-field"
                   name={fieldName}
                   data-name={fieldName}
                   value={fieldValue}
                   data-is-required={!!htmlConstraints?.required}
                   {...(htmlConstraints?.min && {min: htmlConstraints.min.value})}
                   {...(htmlConstraints?.max && {max: htmlConstraints.max.value})}
                   {...attributes}
                   onChange={handleChange}/>
            <div className="range-markers">
                {
                    markers.map((marker) => (
                        <span key={marker} className="range-marker">
                            {marker}
                        </span>
                    ))
                }
            </div>
        </>
    )
}
