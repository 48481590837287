import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import ReactTooltip from "react-tooltip";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import PolPopup from "v4/components/ui/PolPopup/PolPopup";
import useInputSiret from "v4/components/form/Field/components/InputSiret/hooks/useInputSiret";
import SiretPopup from "v4/components/form/Field/components/InputSiret/components/SiretPopup/SiretPopup";

export default function InputSiret({
                                       fieldName,
                                       fieldValue,
                                       prefix,
                                       placeholder,
                                       error,
                                       attr,
                                       inputConstraints,
                                       onChange
                                   }) {
    const {t} = usePolTranslation();
    const {
        handleChange,
        isValueValid,
        handleSiret,
        isPopupOpen,
        toggleIsPopupOpen,
        isLoading,
        isError,
        siretData,
        siretCompanyName,
    } = useInputSiret({fieldValue, onChange});

    return (
        <>
            <div className="siret-input">
                <input className={`input-field ${error ? 'has-error' : ''}`}
                       type="text"
                       data-name={fieldName}
                       id={prefix + fieldName}
                       value={fieldValue}
                       placeholder={placeholder}
                       data-is-required={!!inputConstraints?.required}
                       {...attr}
                       onChange={handleChange}/>
                <button type="button"
                        className="siret-input__button"
                        disabled={!isValueValid || isLoading}
                        data-for="siret-tooltip"
                        data-tip={t(isValueValid ? 'siret_tip' : 'siret_tip_disabled')}
                        onClick={(isValueValid && !isLoading) ? handleSiret : undefined}>
                    <PolIcon icon="download"/>
                </button>
                <ReactTooltip id="siret-tooltip" effect="solid"/>
            </div>

            <PolPopup isOpen={isPopupOpen}
                      closePopup={toggleIsPopupOpen}
                      title={`SIRET: ${fieldValue}`}
                      {...(siretCompanyName && {secondTitle: `${t('company')}: ${siretCompanyName}`.toUpperCase()})}
                      titleNoTranslation
                      classNames={{popup: 'siret-input__popup'}}>
                {isLoading && <p>{t('loading')}</p>}
                {isError && <p>{t('error')}</p>}
                {(!isLoading && siretData) && <SiretPopup siretData={siretData} closePopup={toggleIsPopupOpen}/>}
            </PolPopup>
        </>
    )
}
