import React from 'react';
import FormRow from "v4/components/form/FormRender/components/FormRow/FormRow";
import PolColumns from "v4/components/utils/PolColumns/PolColumns";
import FormRender from "v4/components/form/FormRender/FormRender";

export default function ModuleStatisticsRenderBlockForm({form, formId, onSubmit}) {
    return (
        <FormRender form={form}
                    formId={formId}
                    onSubmit={onSubmit}
                    hasToHideRest>
            <FormRow fieldName="statistic_block_configuration[label]"/>
            <FormRow fieldName="statistic_block_configuration[blockConfiguration][unit]"/>
            <FormRow fieldName="statistic_block_configuration[blockConfiguration][quoteStateIds]"/>
            <PolColumns gridTemplateColumns="1fr 2fr">
                <FormRow fieldName="statistic_block_configuration[blockConfiguration][operation]"/>
                <FormRow fieldName="statistic_block_configuration[blockConfiguration][operationField]"/>
            </PolColumns>
            <FormRow fieldName="statistic_block_configuration[blockConfiguration][groupingField]"/>
            <PolColumns cols={2}>
                <FormRow fieldName="statistic_block_configuration[displayListing]"/>
                <FormRow fieldName="statistic_block_configuration[displaySheet]"/>
            </PolColumns>
        </FormRender>
    )
}
