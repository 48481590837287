import React from 'react';
import {Pie} from "react-chartjs-2";
import usePieStats from "v4/components/ui/PieStats/hooks/usePieStats";
import InlineLoader from "v4/components/ui/InlineLoader/InlineLoader";
import ErrorBoundary from "components/Error/errorBoundary";
import './PieStats.scss';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function PieStats({pie, statsForIds}) {
    const {t} = usePolTranslation();
    const {isLoading, hasData, graphData} = usePieStats(pie, statsForIds);

    return (
        <ErrorBoundary>
            <div className={`pie-stats ${!isLoading && !hasData ? 'pie-stats--empty' : ''}`}>
                <h3 className="pie-stats__title">{pie.label}</h3>
                <div className="pie-stats__graph">
                    {isLoading && <InlineLoader/>}

                    {hasData
                        ? graphData && <Pie data={{...graphData.data}} options={{...graphData.options}}/>
                        : !isLoading && <p className="pie-stats__no-data">{t('turnover_empty_data')}</p>
                    }
                </div>
            </div>
        </ErrorBoundary>
    )
}
