import {captureException} from "@sentry/react";

// Permet d'ignorer les invocations multiples d'une callback avec un timer
export default function debounce(callback, wait = 500) {
    if (typeof callback !== 'function') {
        const err = new Error('callback must be a function');
        captureException(err);
        throw err;
    }

    if (debounce.timer) clearTimeout(debounce.timer);

    if (wait <= 0) {
        return callback();
    }

    // Je ne sais pas pourquoi il faut mettre un setTimeout de 0ms
    // mais sinon ça ne fonctionne pas
    setTimeout(() => (
        debounce.timer =  setTimeout(callback, wait)
    ), 0);
}

export async function asyncDebounce(callback, wait = 500) {
    if (typeof callback !== 'function') {
        const err = new Error('callback must be a function');
        captureException(err);
        throw err;
    }

    clearTimeout(asyncDebounce.timer);

    return new Promise((resolve) => {
        asyncDebounce.timer = setTimeout(() => {
            resolve(callback());
        }, wait);
    });
}
