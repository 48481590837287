import {useEffect, useMemo, useState} from "react";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";
import {PROSPECT_SEARCH, STATISTIC_PROSPECT_LISTING} from "v4/data/apiRoutes";
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";

export default function useProspectsListingStats(checkedIds, isLoading) {
    const [statsForIds, setStatsForIds] = useState(checkedIds?.length ? checkedIds : null);

    const {formMethods} = useSearchContext();
    const [{data: fetchAllIdsData}, fetchAllIds] = useFetch();
    const [{data, isLoading: areModulesLoading}, fetchStats] = useFetch();
    const modules = data?.modules;
    const hasNoModulesConfigured = !Object.values(modules ?? {}).some(module => module.length);
    const allIds = useMemo(() => fetchAllIdsData?.['hydra:member'].map(({id}) => id), [fetchAllIdsData]);

    useEffect(() => {
        if (statsForIds) return;

        fetchAllIds({
            url: generateUrl(PROSPECT_SEARCH, {isAllIds: true}),
            config: {
                method: 'POST',
                body: JSON.stringify(formMethods.getValues()),
            }
        });
    }, [fetchAllIds, formMethods, statsForIds]);

    useEffect(() => {
        if (!allIds) return;

        setStatsForIds(allIds);
    }, [allIds]);

    useEffect(() => {
        if (!statsForIds) return;

        fetchStats({url: generateUrl(STATISTIC_PROSPECT_LISTING)});
    }, [fetchStats, statsForIds]);

    return {
        modules,
        hasNoModulesConfigured,
        areModulesLoading: isLoading || areModulesLoading,
        statsForIds,
    }
}
