// Custom TinyMce Implementation using Custom Plugins
import initPlugins from "v4/components/form/Field/components/InputRichText/plugins/plugins";
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';

import {Editor} from "@tinymce/tinymce-react";
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {createPortal} from "react-dom";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import useToggle from "v4/hooks/useToggle";
import GedPopup from "v4/pages/front/Ged/components/GedPopup/GedPopup";
import {getLSItem} from "v4/services/localStorage.service";

const TOOLBAR_FULL = [
    {name: '', items: ['undo', 'redo']},
    {name: '', items: ['formatselect']},
    {
        name: '',
        items: ['removeformat', 'bold', 'italic', 'underline', 'strikethrough', 'image', 'backcolor', 'forecolor']
    },
    {name: '', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify']},
    {name: '', items: ['bullist', 'numlist', 'outdent', 'indent']},
    {name: '', items: ['link', 'charmap', 'anchor', 'insertdatetime', 'bbcode']},
    {name: '', items: ['help']},
];

const TOOLBAR_RESTRICTED = [
    {name: '', items: ['undo', 'redo']},
    {name: '', items: ['formatselect']},
    {name: '', items: ['removeformat', 'bold', 'italic', 'underline', 'strikethrough', 'backcolor', 'forecolor']},
    {name: '', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify']},
    {name: '', items: ['bullist', 'numlist', 'outdent', 'indent']},
    {name: '', items: ['link']},
];

export default React.forwardRef(function InputRichText({
                                                           fieldName,
                                                           fieldValue,
                                                           inputConstraints,
                                                           isRestricted = false,
                                                           onChange,
                                                           height = 250,
                                                           overrideTinyMceInitConfig = {},
                                                           overrideTinyMceProps = {},
                                                       }, ref) {
    const [isShowing, toggleShowing] = useToggle(false);
    const [popupGed, setPopupGed] = useState(null);
    const initialValue = useRef(fieldValue);

    const openGedPicker = useCallback((callback, expansionAllowed, returnType) => {
        toggleShowing();
        setPopupGed(<GedPopup callback={callback} toggle={toggleShowing} expansionAllowed={expansionAllowed}
                              returnType={returnType}/>);
    }, [toggleShowing]);

    const language = getLSItem('i18nextLng', 'string').includes('fr') ? 'fr' : 'en';
    const {fullname} = getLSItem('user');

    // Init Custom Plugins
    const {
        pluginList,
        additionalContentCss,
        toolbarWithPlugins
    } = useMemo(() => initPlugins({
        toolbar: isRestricted ? TOOLBAR_RESTRICTED : TOOLBAR_FULL,
        fieldName,
        openGedPicker,
    }), [openGedPicker, fieldName, isRestricted]);

    return (
        <div className="input-field-rich-text-editor" data-is-required={!!inputConstraints?.required}>
            <Editor
                textareaName={fieldName}
                initialValue={initialValue.current}
                init={{
                    image_title: true,
                    file_picker_types: 'image',
                    file_picker_callback: (callback) => openGedPicker(base64 => callback(base64), ['png', 'bmp', 'jpg', 'jpeg', 'gif'], 'base64'),
                    height: height,
                    menubar: false,
                    plugins: pluginList,
                    toolbar: toolbarWithPlugins,
                    content_style: contentCss + additionalContentCss,
                    language: language === 'fr' ? 'fr_FR' : undefined, // à voir si l'on rend la langue plus dynamique
                    language_url: language === 'fr' ? process.env.REACT_APP_PUBLIC_URL + 'tinymce_lang/' + language + '.js' : undefined,
                    insertdatetime_formats: language === 'fr' ? ['%d/%m/%Y', '%H:%M', `%d/%m/%Y ${fullname}`, `%d/%m/%Y %H:%M ${fullname}`, fullname] : ['%m/%d/%Y', '%H:%M', `%m/%d/%Y ${fullname}`, `%m/%d/%Y %H:%M ${fullname}`, fullname],
                    ...overrideTinyMceInitConfig,
                }}
                value={fieldValue}
                onEditorChange={onChange}
                ref={(editor) => {
                    if (ref) ref.current = editor;

                    if (editor) editor.elementRef.current.TinyMCE = editor;
                }}
                data-name={fieldName}
                {...overrideTinyMceProps}
            />

            {popupGed && createPortal(
                <ToggleComponent isShowing={isShowing} shouldRemoveFromDom={true}>
                    {popupGed}
                </ToggleComponent>,
                document.querySelector('body'))}
        </div>
    )
})
