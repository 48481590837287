import React, {forwardRef} from 'react';
import InlineLoader from "v4/components/ui/InlineLoader/InlineLoader";
import BlocksStats from "v4/components/ui/BlocksStats/BlocksStats";
import TablesStats from "v4/components/ui/TablesStats/TablesStats";
import ProductsBlocksStats from "v4/components/ui/ProductsBlocksStats/ProductsBlocksStats";
import PolButton from "v4/components/ui/PolButton/PolButton";
import {routesBase} from "v4/data/appRoutes";
import {PolIcon} from "components/PolIcon/policon";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {SearchProvider} from "v4/contexts/SearchContext/SearchContext";
import SearchContextPagination from "v4/components/utils/SearchContextPagination/SearchContextPagination";

function ProspectStats360Modules({modules, filters, isLoading}, gridRef) {
    const {t} = usePolTranslation();

    if (isLoading) return <InlineLoader/>;

    if (!modules) return null;

    const hasNoModulesConfigured = !Object.values(modules).some(module => !!module?.length);
    if (hasNoModulesConfigured) return (
        <div className="no-stats">
            <p>{t('no_modules')}</p>
            <PolButton to={routesBase.adminModuleStatistics}>
                <PolIcon icon="tools"/>
                {t('configure')}
            </PolButton>
        </div>
    );

    return (
        <SearchProvider>
            <div className="stats-360__grid" ref={gridRef}>
                {!!modules.blocks?.length && <BlocksStats blocks={modules.blocks}/>}
                {!!modules.tables?.length && <TablesStats tables={modules.tables}/>}
                {!!modules.products?.length && <ProductsBlocksStats products={modules.products} filters={filters}/>}
            </div>

            {!!modules.products?.length && <SearchContextPagination canChangeItemsPerPage={false}/>}
        </SearchProvider>
    )
}

export default forwardRef(ProspectStats360Modules);
