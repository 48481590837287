import React from 'react';

export default function InputNumber({
                                        fieldName,
                                        fieldValue,
                                        prefix,
                                        placeholder,
                                        error,
                                        attr,
                                        inputConstraints,
                                        onChange
                                    }) {

    const handleChange = ({currentTarget: {value}}) => {
        onChange(value);
    }

    return (
        <input className={`input-field${error ? ' has-error' : ''}`}
               type="number"
               data-name={fieldName}
               id={prefix + fieldName}
               value={fieldValue}
               placeholder={placeholder}
               data-is-required={!!inputConstraints?.required}
               {...(inputConstraints?.min && {min: inputConstraints.min.value})}
               {...(inputConstraints?.max && {max: inputConstraints.max.value})}
               {...attr}
               onChange={handleChange}/>
    )
}
