import useFetch from "v4/hooks/useFetch";
import {useEffect, useMemo, useRef} from "react";
import {generateUrl} from "v4/services/api.service";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import {STATISTIC_PROSPECT} from "v4/data/apiRoutes";
import {useForm, useWatch} from "react-hook-form";

// Force minimum height to be the highest value of the height of the grid
const resizeObserver = new ResizeObserver(entries => {
    const grid = entries[0];
    const gridHeight = grid.contentRect.height;

    grid.target.style.minHeight = Math.max(gridHeight, grid.target.clientHeight) + 'px';
});

export default function useGetProspectStats360Modules(isProductView) {
    const {prospectId} = useProspectPageContext();
    const gridRef = useRef(null);
    const methods = useForm();
    const [{data, isLoading}, fetchProspectStats360] = useFetch();
    const modules = useMemo(() => {
        if (!data?.modules) return null;

        const {products, ...rest} = data?.modules ?? {};

        return isProductView ? {products} : rest;
    }, [data, isProductView]);
    const hasProducts = !!data?.modules?.products.length;

    useEffect(() => {
        fetchProspectStats360({url: generateUrl(STATISTIC_PROSPECT, {prospectId})});
    }, [fetchProspectStats360, prospectId]);

    useEffect(() => {
        const gridElement = gridRef.current;

        if (gridElement) {
            resizeObserver.observe(gridElement);

            return () => resizeObserver.unobserve(gridElement);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRef.current]);

    const GETFilters = useWatch({
        control: methods.control,
        defaultValue: {sort: '', search: ''},
    });

    const filters = useMemo(() => ({
        GET: {
            ...GETFilters,
            ...(GETFilters.sort && {
                direction: GETFilters.sort.startsWith('-') ? 'DESC' : 'ASC',
                sort: GETFilters.sort.replace(/^-/, ''),
            }),
        },
        POST: {},
    }), [GETFilters]);

    return {
        modules,
        hasProducts,
        isLoading,
        gridRef,
        methods,
        filters,
    }
}
