import React from 'react';
import InlineLoader from "v4/components/ui/InlineLoader/InlineLoader";
import useProspectsListingStats
    from "v4/pages/front/Prospects/components/ProspectsListing/components/ProspectsListingStats/hooks/useProspectsListingStats";
import BlocksStats from "v4/components/ui/BlocksStats/BlocksStats";
import TablesStats from "v4/components/ui/TablesStats/TablesStats";
import PiesStats from "v4/components/ui/PiesStats/PiesStats";
import {PolIcon} from "components/PolIcon/policon";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import PolButton from "v4/components/ui/PolButton/PolButton";
import {routesBase} from "v4/data/appRoutes";
import './ProspectsListingStats.scss';

export default function ProspectsListingStats({checkedIds, isLoading}) {
    const {t} = usePolTranslation();
    const {
        modules,
        hasNoModulesConfigured,
        areModulesLoading,
        statsForIds,
    } = useProspectsListingStats(checkedIds, isLoading);

    if (areModulesLoading) return <InlineLoader/>;

    if (!modules) return null;

    if (hasNoModulesConfigured) return (
        <div className="no-stats">
            <p>{t('no_modules')}</p>
            <PolButton to={routesBase.adminModuleStatistics}>
                <PolIcon icon="tools"/>
                {t('configure')}
            </PolButton>
        </div>
    );

    return (
        <div className="prospects-listing__stats">
            {modules.blocks && <BlocksStats blocks={modules.blocks} statsForIds={statsForIds} shouldShowGraphInPopup/>}
            {modules.pies && <PiesStats pies={modules.pies} statsForIds={statsForIds}/>}

            <div className="full-width">
                {modules.tables && <TablesStats tables={modules.tables} statsForIds={statsForIds}/>}
            </div>
        </div>
    )
}
